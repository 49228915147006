import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    domestic: {
        'Indore': { current: 61500, change: 50 },
        'Kanpur': { current: 61500, change: 50 },
        'Rajkot': { current: 61500, change: 50 },
        'Delhi': { current: 85500, change: -1000 },
        'Kota': { current: 61500, change: 50 },
        'karnal_faq': { current: 56500, change: 500 },
    },
    cbot: [{
        month: 'Dec-23',
        current: 591,
        change: -24
    },
    {
        month: 'Jan-24',
        current: 591,
        change: -24
    },
    {
        month: 'Feb-24',
        current: 591,
        change: -24
    },
    {
        month: 'Mar-24',
        current: 591,
        change: -24
    }],
    fob: {
        'argentina': { current: 1100, change: 50 },
        'france_grade_1': { current: 1200, change: 50 },
        'us_hrw': { current: 1300, change: 50 },
        'us_srw': { current: 1400, change: -1000 },
        'australia': { current: 1500, change: 50 },
        'black_sea': { current: 1100, change: 20 }
    },
    currency:{
        'India':{current:1100, change:50},
        'United Kingdom':{current:1100, change:50},
        'European Union':{current:1100, change:50},
        'Japan':{current:1100, change:50},
    }
}

function WheatDailySummary({ dataObject = sampleResponse }) {

    // const [dataObject, setDataObject] = useState(data);

    // useEffect(() => {
    //     setDataObject(data);
    // }, [data]);

    return (
        <TableContainer  className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Mandi Prices(INR/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Indore</TableCell>
                        <TableCell>{dataObject['domestic']['Indore']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Indore']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Kanpur</TableCell>
                        <TableCell>{dataObject['domestic']['Kanpur']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Kanpur']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rajkot</TableCell>
                        <TableCell>{dataObject['domestic']['Rajkot']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Rajkot']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Delhi</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Kota</TableCell>
                        <TableCell>{dataObject['domestic']['Kota']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Kota']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>International Prices</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>CBOT (cents/bushel)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][0]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{dataObject['cbot'][1]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][2]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{dataObject['cbot'][3]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>FOB (USD/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Argentina</TableCell>
                        <TableCell>{dataObject['fob']['argentina']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['argentina']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>France</TableCell>
                        <TableCell>{dataObject['fob']['france_grade_1']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['france_grade_1']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>USA (HRW)</TableCell>
                        <TableCell>{dataObject['fob']['us_hrw']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['us_hrw']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>USA (SRW)</TableCell>
                        <TableCell>{dataObject['fob']['us_srw']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['us_srw']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>India (INR)</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>United Kingdom (GBP)</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>European Union (EURO)</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Japan (JPY)</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default WheatDailySummary;