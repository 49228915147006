import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardHeader, CardContent } from '@mui/material';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';

function ClientPersonalInfoForm({ setClientId }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [saveFlag, setSaveFlag] = useState(false);

    const resetForm = () => {
        setName('')
        setEmail('');
        setPhone('');
        setOrganization('');
        setPassword('');
        setVerifyPassword('');
        setSaveFlag(false);
    }

    useEffect(() => {
        if(saveFlag==true){
            setSaveFlag(false);
        }
    }, [name, email, phone, organization, password, verifyPassword]);

    const saveInfo = () => {
        if (password !== verifyPassword) {
            alert('Verfication Password Not Same');
        }
        else {
            const info = {
                name: name,
                email: email,
                phone: phone,
                organization: organization,
                password: password,
            };
            savePersonalInfo(info);
        }
    };

    const savePersonalInfo = (info) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('name', info['name']);
        formData.append('email', info['email']);
        formData.append('phone', info['phone']);
        formData.append('organization', info['organization']);
        formData.append('password', info['password']);
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/`, {
            method: 'POST',
            headers: {
                Authorization: token,
            },
            body: formData
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    setClientId(r['data']['client_id']);
                    alert('Client Created Successfully, Please add subscriptions')
                    setSaveFlag(true);
                } else {
                    alert('Unable to create Client');
                }
            })
    }

    return (
        <Card>
            <CardHeader>
                <h3> Add Client Personal Info </h3>
            </CardHeader>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={true} label='Name' currentValue={name} handleInput={setName} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={true} label='Email' currentValue={email} handleInput={setEmail} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={false} label='Contact Number (Please include country code)' currentValue={phone} handleInput={setPhone} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={true} label='Organization' currentValue={organization} handleInput={setOrganization} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={true} label='Client Password' currentValue={password} handleInput={setPassword} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldInput isRequired={true} label='Verify Password' currentValue={verifyPassword} handleInput={setVerifyPassword} />
                    </Grid>
                    <Grid item xs={6}>
                        <TwoParallelButtons label1='Save Client' label2='Reset Form' action1={saveInfo} action2={resetForm} />
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ float: 'right' }}>
                            {(saveFlag) ? 'Saved' : 'Pending'}
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
}

export default ClientPersonalInfoForm;