import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import SetReportFactors from "./SetReportFactors";
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

function EditReportFactors({ reportId }){

    const navigate = useNavigate();
    const [msg, setMsg] = useState('Please Fetch Factors available in this report');
    const [reportContextObj, setReportContextObj] = useState({
        section_list: [],
        active_screen: {}
    });

    const fetchSections = () => {
        const token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}/context`
        fetch(url, {
            method:'GET',
            headers:{
                Authorization:token,
            }
        })
        .then(r => r.json())
        .then(res => {
            if(res.success == true){
                const sectionList = res.data['section_list'];
                const factors = sectionList.map(t => {
                    return {
                        section_id:t.section_id,
                        section_name:t.section_name, 
                        section_code:t.section_code, 
                        section_priority:t.section_priority, 
                        section_active:t.section_active,
                        section_delete_enable:t.section_delete_enable,
                    }
                });
                const context = {}
                context.section_list = factors;
                context.active_section = factors[0];
                setReportContextObj(context);
                setMsg('Please select/Reselect the factors you want in report');
            } else {
                alert('Error Fetching Sections');
            }
        })
    }

    const saveFactors = (factors) => {
        const copy = {};
        copy['section_list'] = factors;
        editReport(copy);
        setMsg('Factors Saved Successfully');
    }

    const editReport = (reqObj) => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}`, {
            method:'PUT',
            headers:{
                Authorization:token,
                'Content-Type':'application/json',
            },
            body:JSON.stringify(reqObj)
        }).then(r => r.json())
        .then(r => {
            if (r.success === true){
                const context = {}
                context.section_list = r.data['section_list']
                context.active_section = r.data['section_list'][0]
                setReportContextObj(context);
            } else {
                alert('Unable to update Report');
            }
        })
    }

    const navigateWorkflow = () => {
        if (reportContextObj.section_list.length == 0){
            alert('Please Fetch Sections first');
            return;
        }
        const section_list = reportContextObj.section_list;
        const finalContext = {};
        finalContext.section_list = section_list.filter((t) => t.section_active == true);
        finalContext.active_section = finalContext.section_list[0];
        navigate('/dashboard/reports/workflow', {state: finalContext});
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>{msg}</h5>
            </Grid>
            <Grid item xs={12}>
                <SetReportFactors initCheckboxList={reportContextObj.section_list} saveFactors={saveFactors} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons 
                    label1='Fetch Factors' 
                    label2='Initiate Workflow' 
                    action1={fetchSections} 
                    action2={navigateWorkflow} 
                />
            </Grid>
        </Grid>
    )
}

export default EditReportFactors;