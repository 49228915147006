import { useState } from "react";

import Grid from "@mui/material/Grid";
import SelectInput from "components/input/raw_inputs/SelectInput";
import DatePickerInput from "components/input/raw_inputs/DatePickerInput";
import AlertDialogBox from "components/input/custom_feedback/AlertDialogBox";
import CommodityReportTypeSelector from "dashboard/reports/common/CommodityReportTypeSelector";
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function SelectReportParams({ submitParams=()=>{}, resetForm= () => {} }) {

    const [reportParams, setReportParams] = useState({
      commodity_name: "",
      report_type: "",
      publish_date: "",
      medium:"",
    });
  
    const [warning, setWarning] = useState({
      warningMsg: '', show: false
    });
  
    const resetInput = () => {
      setReportParams({ commodity_name: "", report_type: "", publish_date: "" });
      resetForm();
    };
  
    const setCommodity = (commodityName) => {
      const copy = { ...reportParams };
      copy["commodity_name"] = commodityName;
      setReportParams(copy);
    };
  
    const setReportType = (reportType) => {
      const copy = { ...reportParams };
      copy["report_type"] = reportType;
      setReportParams(copy);
    };
  
    const setReportDate = (newDate) => {
      const copy = { ...reportParams };
      copy["publish_date"] = newDate;
      setReportParams(copy);
    }

    const setMedium = (medium) => {
      const copy = { ...reportParams };
      copy['medium'] = medium;
      setReportParams(copy);
    }
  
    const submit = () => {
      if(reportParams['commodity_name'] === ''){
        setWarning({ warningMsg: 'Commodity Cannot be Empty', show: true });
      } else if(reportParams['report_type']===''){
        setWarning({ warningMsg: 'ReportType Cannot be Empty', show: true });
      } else if(reportParams['publish_date']===''){
        setWarning({ warningMsg: 'ReportDate Cannot be Empty', show: true });
      } else if(reportParams['medium']===''){
        setWarning({ warningMsg: "Medium Cannot be Empty", show :true});
      }
      else {
        submitParams(reportParams);
      }
    };
  
    const closeDialogBox = () => {
      setWarning({warningMsg: '', show: false});
    }
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CommodityReportTypeSelector 
            commodityName={reportParams["commodity_name"]} 
            reportType={reportParams["report_type"]} 
            setCommodity={setCommodity}
            setReportType={setReportType} 
          />
        </Grid>
        <Grid item xs={12} className="init-report-section-core-input">
          <SelectInput
            label="Medium"
            optionList={[{label:'Physical', value:'physical'}, {label:'Digital', value:'digital'}]}
            helperText = "*Mandatory"
            handleInput={setMedium}
            value={reportParams["medium"]}
          />
        </Grid>
        <Grid item xs={12} className="init-report-section-core-input">
          <DatePickerInput 
            label='Report Publish Date'
            value={reportParams['publish_date']}
            setNewDate={setReportDate}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <TwoParallelButtons
              label1="Initiate Report"
              label2="ResetValues"
              action1={submit}
              action2={resetInput}
            />
          </div>
        </Grid>
        <AlertDialogBox
          alertMsg={warning['warningMsg']}
          openFlag={warning['show']}
          handleClose={closeDialogBox}
        />
      </Grid>
    );
  }

  export default SelectReportParams;