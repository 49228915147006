// import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import VerticalProgressBar from "components/input/progress_flow/vertical_progress_bar/VerticalProgressBar";

function ReportProgressBar({ stepList, setSectionId }){

    const navigate = useNavigate();

    const handleProgressChange = (step) => {
        const section = stepList[step];
        setSectionId(section.section_id);
    }

    const finishReport = () => {
        alert('Report completed Successfully');
        navigate('/dashboard/reports/archive', {state: {}});
    }

    return (
        <VerticalProgressBar 
            steps = {stepList}
            passStageInfo={handleProgressChange}
            finishProgress={finishReport}
        />
    );

}

export default ReportProgressBar;