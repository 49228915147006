import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './import_parity_table.css';

const sampleResponse = {
    "Australia": {
        "basic_cost": 26470.0,
        "clearing_charges": 14.0,
        "commodity_name": "Wheat",
        "date": "2024-03-06",
        "exchange_rate": 82.9,
        "fob_replacement_inr": 29970.0,
        "fob_replacement_usdmt": 361.5,
        "freight_domestic": 3000.0,
        "freight_port_to_mill": 12.0,
        "id": 3,
        "import_parity": 57.5,
        "local_clearing": 500.0,
        "premium": -10.0,
        "trade_source": "Australia",
        "wheat_cfr_usdmt": 288.0,
        "wheat_total_usdmt": 304.0
    },
    "Black Sea": {
        "basic_cost": 25470.0,
        "clearing_charges": 18.0,
        "commodity_name": "Wheat",
        "date": "2024-03-06",
        "exchange_rate": 82.9,
        "fob_replacement_inr": 28970.0,
        "fob_replacement_usdmt": 349.4,
        "freight_domestic": 3000.0,
        "freight_port_to_mill": 12.0,
        "id": 4,
        "import_parity": 61.4,
        "local_clearing": 500.0,
        "premium": 0.0,
        "trade_source": "Black Sea",
        "wheat_cfr_usdmt": 258.0,
        "wheat_total_usdmt": 288.0
    }
}

function WheatImportParityTable({ dataObject = sampleResponse }) {

    if (dataObject !== null && Object.keys(dataObject).length == 2) {
        const keyA = Object.keys(dataObject)[0]
        const keyB = Object.keys(dataObject)[1]

        return (
            <TableContainer  className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
                <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                    <TableBody>
                        <TableRow className='table-header-tg'>
                            <TableCell>Parity Calculation</TableCell>
                            <TableCell>{keyA}</TableCell>
                            <TableCell>{keyB}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>Basic cost of Indian wheat (Rs/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['basic_cost']}</TableCell>
                            <TableCell>{dataObject[keyB]['basic_cost']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Freight From Origin to Destination</TableCell>
                            <TableCell>{dataObject[keyA]['freight_domestic']}</TableCell>
                            <TableCell>{dataObject[keyB]['freight_domestic']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>Local Clearing from rake Point to Mill</TableCell>
                            <TableCell>{dataObject[keyA]['local_clearing']}</TableCell>
                            <TableCell>{dataObject[keyB]['local_clearing']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Indian wheat at South Destination</TableCell>
                            <TableCell>{dataObject[keyA]['fob_replacement_inr']}</TableCell>
                            <TableCell>{dataObject[keyB]['fob_replacement_inr']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>USD-INR Rate</TableCell>
                            <TableCell>{dataObject[keyA]['exchange_rate']}</TableCell>
                            <TableCell>{dataObject[keyB]['exchange_rate']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dollar Price of Indian Wheat in Local Market (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['fob_replacement_usdmt']}</TableCell>
                            <TableCell>{dataObject[keyB]['fob_replacement_usdmt']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>FOB replacement Basis Local Market ( Bangalore) </TableCell>
                            <TableCell>{dataObject[keyA]['fob_replacement_inr']}</TableCell>
                            <TableCell>{dataObject[keyB]['fob_replacement_inr']}</TableCell>
                        </TableRow>
                        {/*  */}
                        <TableRow>
                            <TableCell>USD-INR Rate</TableCell>
                            <TableCell>{dataObject[keyA]['exchange_rate']}</TableCell>
                            <TableCell>{dataObject[keyB]['exchange_rate']}</TableCell>
                        </TableRow>
                        {/*  */}

                        <TableRow className='color-row'>
                            <TableCell>Parity for Imported Wheat basis Dom Replacement (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['fob_replacement_usdmt']}</TableCell>
                            <TableCell>{dataObject[keyB]['fob_replacement_usdmt']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow>
                        <TableRow>
                            <TableCell>Wheat CFR Price (in USD/MT) </TableCell>
                            <TableCell>{dataObject[keyA]['wheat_cfr_usdmt']}</TableCell>
                            <TableCell>{dataObject[keyB]['wheat_cfr_usdmt']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>Clearing Charges of Imported Wheat (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['clearing_charges']}</TableCell>
                            <TableCell>{dataObject[keyB]['clearing_charges']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Local Freight from Port to Mill (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['freight_port_to_mill']}</TableCell>
                            <TableCell>{dataObject[keyB]['freight_port_to_mill']}</TableCell>
                        </TableRow>

                        <TableRow className='color-row'>
                            <TableCell>Premium/Discount for Imported wheat (in USD/MT) </TableCell>
                            <TableCell>{dataObject[keyA]['premium']}</TableCell>
                            <TableCell>{dataObject[keyB]['premium']}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Import Parity (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['import_parity']}</TableCell>
                            <TableCell>{dataObject[keyB]['import_parity']}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return <></>;
    }
}

export default WheatImportParityTable;