import { useState, useContext, useEffect } from "react";
import { AuthContext } from 'auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Card } from 'react-bootstrap';

function Login(){

    const navigate = useNavigate();
    const location = useLocation();
    const [targetPath, setTargetPath] = useState('/dashboard');

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token){
            navigate('/dashboard');
        } else {
            if(location.state){
                setTargetPath(location.state)
            }
        }
    }, [])

    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (val) => {
        setEmail(val);
    }

    const handlePassword = (val) => {
        setPassword(val);
    }

    const submitLogin = (event) => {
        event.preventDefault();
        const body = {
            username: email,
            password: password,
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(r => r.json())
        .then(r => {
            if(r.success == true){
                const user = {'token':r.token, 'email':r.email}
                const cb = () => { navigate(targetPath) }
                login(user, cb)
            } else {
                alert('Incorrect Credentials');
            }
        })
    };

    return (
        <Container fluid>
            <Card>
                <Card body>
                    <Card.Header>
                        <h2>Agalabs Flask</h2>
                    </Card.Header>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                onChange={e => handleEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={e => handlePassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={e => submitLogin(e)} >
                            Submit
                        </Button>
                    </Form>
                </Card>
            </Card>
        </Container>
    );
}

export default Login;