import ChartMapper from "./ChartMapper";
import Grid from '@mui/material/Grid';

function ChartPreview({ dataObject }) {

    if (dataObject !== null && Object.keys(dataObject).length > 0 && dataObject['data_present'] == true) {
        return (
            <Grid container spacing={0.2}>
                <Grid item xs={12}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', marginBottom: '0rem!important' }}><b>{dataObject['visualization_header']}</b></p>
                </Grid>
                <Grid item xs={12}>
                    <ChartMapper
                        type={dataObject['visualization_data']['type']}
                        info={dataObject['visualization_data']['info']['data']}
                        metadata={dataObject['visualization_data']['metadata']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', marginBottom: '0rem!important' }}>{dataObject['visualization_footer']}</p>
                </Grid>
            </Grid>
        )
    } else {
        return <></>;
    }

}

export default ChartPreview;