import { useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function SoybeanParityForm({ sectionObj }){

    const [premium1, setPremium1] = useState(0);
    const [premium2, setPremium2] = useState(0);
    const [premium3, setPremium3] = useState(0);

    const submitForm = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('report_date', moment(sectionObj['publish_date']).format('YYYY-MM-DD'))
        formData.append('premium_1', premium1);
        formData.append('premium_2', premium2);
        formData.append('premium_3', premium3);
        const url = `${process.env.REACT_APP_SERVER_URL}/calculations/soybeanparity/`
        fetch(url, {
            method:'POST',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('Data Created');
            } else {
                alert('Failure to create records');
            }
        });
    }

    const deleteRecords = () => {
        const token = localStorage.getItem('token');
        const reportDate = moment(sectionObj['publish_date']).format('YYYY-MM-DD')
        const url = `${process.env.REACT_APP_SERVER_URL}/calculations/soybeanparity/${reportDate}`
        fetch(url, {
            method:'DELETE',
            headers:{
                Authorization:token
            },
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('Data Delete');
            } else {
                alert('Failure to delete records');
            }
        });
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextFieldInput label='Premium 1' currentValue={premium1} handleInput={setPremium1} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput label='Premium 2' currentValue={premium2} handleInput={setPremium2} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput label='Premium 3' currentValue={premium3} handleInput={setPremium3} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='Submit' label2='delete' action1={submitForm} action2={deleteRecords} /> 
            </Grid>
        </Grid>
    )
    
}

export default SoybeanParityForm;