import Grid from '@mui/material/Grid';
import Divider from "@mui/material/Divider";
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import EditReportMapper from './EditReportMapper';

import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';


function EditReportModal({ modalStatus, handleClose, reportDetails = { report_id: -1, medium: '', commodity_name: '', commodity_id: 0, report_type: '', publish_date: '', report_content: {} }, operation='edit' }) {

    const deleteReport = () => {
        alert('NOT DECIDED ON THIS FEATURE YET, BACKEND DONE');
    }

    return (
        <Modal show={modalStatus} onHide={handleClose} size='xl'>
            <Modal.Header>
                <h3>Report Details</h3>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <p><b>Report Id :</b> {reportDetails.report_id} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Commodity Name:</b> {reportDetails.commodity_name}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Report Type :</b> {reportDetails.report_type}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Publish Date:</b> {moment(reportDetails.publish_date).format('YYYY-MM-DD')}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider> Modal Operation </Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <EditReportMapper reportDetails={reportDetails} operation={operation} />
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ float: 'right' }}>
                    <TwoParallelButtons label1='close' label2='Delete Report' action1={handleClose} action2={deleteReport} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditReportModal;