import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

function PreviewPhysicalReport({ reportId }){

    const fetchFile = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/download`, {
            method:'GET',
            headers:{
                Authorization: token
            }
        }).then(r => r.json())
        .then(res => {
            if (res.success == true){
                window.open(res.data, '_blank');
            } else {
                alert('Error Fetching Report')
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button variant='contained' onClick={() => fetchFile()}>Download Report</Button>
            </Grid>
        </Grid>
    )
}

export default PreviewPhysicalReport;