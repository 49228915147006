import Grid from '@mui/material/Grid';

function ImagePreview({ dataObject }) {
    
    if (dataObject !== null && Object.keys(dataObject).length > 0 && dataObject['data_present']==true) {
        return (
            <Grid container spacing={0.1}>
                <Grid item xs={12}>
                    <span style={{fontFamily: 'Arial, sans-serif', fontSize:'14px'}}>{dataObject['visualization_header']}</span>
                </Grid>
                <Grid item xs={12}>
                    <img src={dataObject['visualization_data']['image_link']} className='imguploaded'/>
                </Grid>
                <Grid item xs={12}>
                    <span style={{fontFamily: 'Arial, sans-serif', fontSize:'14px'}}>{dataObject['visualization_footer']}</span>
                </Grid>
            </Grid>
        )
    } else {
        return <></>;
    }

}

export default ImagePreview;