import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const sampleRepsonse = {
    "cash_discount": {
        "change": 0.0,
        "current": 0.0,
        "previous": 0.0
    },
    "claim": {
        "change": 3.0,
        "current": -1019.0,
        "previous": -1022.0
    },
    "crush_expenses": {
        "change": 0.0,
        "current": 1350.0,
        "previous": 1350.0
    },
    "date": {
        "current": "2023-07-20",
        "previous": "2023-07-19"
    },
    "meal_price": {
        "change": -295.0,
        "current": 41195.0,
        "previous": 41490.0
    },
    "meal_share": {
        "change": -241.9,
        "current": 33779.9,
        "previous": 34021.8
    },
    "net_crush_margin": {
        "change": -85.1,
        "current": 401.1,
        "previous": 486.2
    },
    "oil_price": {
        "change": 100.0,
        "current": 9500.0,
        "previous": 9400.0
    },
    "oil_share": {
        "change": 180.0,
        "current": 17100.0,
        "previous": 16920.0
    },
    "quality_tolerance": {
        "change": 0.0,
        "current": 0.0,
        "previous": 0.0
    },
    "seed_price": {
        "change": -150.0,
        "current": 50950.0,
        "previous": 51100.0
    },
    "total_cost": {
        "change": -147.0,
        "current": 51281.0,
        "previous": 51428.0
    },
    "total_realization": {
        "change": -61.9,
        "current": 50879.9,
        "previous": 50941.8
    }
}


function SoybeanCrushMarginTable({ dataObject = sampleRepsonse }){

    return (
        <TableContainer component={Paper} className='tableContainer' sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                <TableRow className='table-header-tg'>
                        <TableCell >Soybeans Crush Parity</TableCell>
                        <TableCell>{dataObject['date']['current']}</TableCell>
                        <TableCell>{dataObject['date']['previous']}</TableCell>
                        <TableCell>Change</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean Price Ex. Indore (Rs./Tonne)</TableCell>
                        <TableCell><b>{dataObject['seed_price']['current']}</b></TableCell>
                        <TableCell><b>{dataObject['seed_price']['previous']}</b></TableCell>
                        <TableCell><b>{dataObject['seed_price']['change']}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Claim (2%)</TableCell>
                        <TableCell>{dataObject['claim']['current']}</TableCell>
                        <TableCell>{dataObject['claim']['previous']}</TableCell>
                        <TableCell>{dataObject['claim']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Crush Expenses (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['crush_expenses']['current']}</TableCell>
                        <TableCell>{dataObject['crush_expenses']['previous']}</TableCell>
                        <TableCell>{dataObject['crush_expenses']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total Cost (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['total_cost']['current']}</TableCell>
                        <TableCell>{dataObject['total_cost']['previous']}</TableCell>
                        <TableCell>{dataObject['total_cost']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Solvent Ext. Oil Price (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['oil_price']['current']}</TableCell>
                        <TableCell>{dataObject['oil_price']['previous']}</TableCell>
                        <TableCell>{dataObject['oil_price']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Realization Oil Share (18%)</TableCell>
                        <TableCell>{dataObject['oil_share']['current']}</TableCell>
                        <TableCell>{dataObject['oil_share']['previous']}</TableCell>
                        <TableCell>{dataObject['oil_share']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean DOC Ex Indore (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['meal_price']['current']}</TableCell>
                        <TableCell>{dataObject['meal_price']['previous']}</TableCell>
                        <TableCell>{dataObject['meal_price']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Realization Meal Share</TableCell>
                        <TableCell>{dataObject['meal_share']['current']}</TableCell>
                        <TableCell>{dataObject['meal_share']['previous']}</TableCell>
                        <TableCell>{dataObject['meal_share']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Total Realization (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['total_realization']['current']}</TableCell>
                        <TableCell>{dataObject['total_realization']['previous']}</TableCell>
                        <TableCell>{dataObject['total_realization']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Net Crush Margin (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['current']}</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['previous']}</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SoybeanCrushMarginTable;