import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    ncdex:{
        "Jaipur":{
            "change": "nja",
            "current": "njb"
        }
    },
    spot:{
        "Rapeseed":{
            "change": "spa",
            "current": "spb"
        },
        "Rapeseed Meal":{
            "change": "spama",
            "current": "spamb"
        },
        "Rapeseed Oil":{
            "change": "spaoa",
            "current": "spaob"
        }
    },
    currency:{
        "India": {
            "change": "1",
            "current": "2"
        },
        "Brazil": {
            "change": "3",
            "current": "4"
        },
        "Argentina": {
            "change": "5",
            "current": "6"
        },
        "Ukraine": {
            "change": "7",
            "current": "8"
        },
        "Malaysia":{
            "change": "9",
            "current": "10"
        },
        "China": {
            "change": "11",
            "current": "12"
        },
        "Australia": {
            "change": "13",
            "current": "14"
        },
        "Japan": {
            "change": "15",
            "current": "16"
        },
        "Canada": {
            "change": "17",
            "current": "18"
        },
    }
}

function RapeseedDailySummary({ dataObject = sampleResponse}){


    return (
        <TableContainer component={Paper} className='tableContainer' sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Spot Price (INR/MT)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Jaipur</TableCell>
                        <TableCell>{dataObject['ncdex']['Jaipur']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['Jaipur']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Indian Domestic Update (Jaipur) (INR/MT)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rapeseed</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed']['current']}</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Rapeseed Meal</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed Meal']['current']}</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed Meal']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rapeseed Oil</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed Oil']['current']}</TableCell>
                        <TableCell>{dataObject['spot']['Rapeseed Oil']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>India (INR)</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Brazil (BRL)</TableCell>
                        <TableCell>{dataObject['currency']['Brazil']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Brazil']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Argentina (ARS)</TableCell>
                        <TableCell>{dataObject['currency']['Argentina']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Argentina']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Ukraine (UAH)</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Malaysia</TableCell>
                        <TableCell>{dataObject['currency']['Malaysia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Malaysia']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>China (CNY)</TableCell>
                        <TableCell>{dataObject['currency']['China']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['China']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Australia (AUD)</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Japan (JPY)</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Canada (CAD)</TableCell>
                        <TableCell>{dataObject['currency']['Canada']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Canada']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RapeseedDailySummary;