import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

function TwoParallelButtons ({ label1='Label 1', label2='Label 2', action1= () => {}, action2= ()=>{} }){

    return (
        <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" onClick={() => action1()}>{label1}</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => action2()} color="success">
                  {label2}
                </Button>
              </Grid>
            </Grid>
    )

}

export default TwoParallelButtons;