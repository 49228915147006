import React from 'react';
import './RichText.css';

function EditorStyleButton({ onToggle, style, label, active }) {
    const toggleEvent = (e) => {
        e.preventDefault();
        onToggle(style);
    };

    let className = 'RichEditor-styleButton';
    if (active) {
        className += ' RichEditor-activeButton';
    }

    return (
        <span className={className} onMouseDown={toggleEvent}>
            {label}
        </span>
    );
}

export default EditorStyleButton;