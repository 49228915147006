import { IconButton } from '@mui/material';
import DeleteIcon  from '@mui/icons-material/Delete';

function CustomDeleteIcon({ action, enableIcon }){

    return (
        <IconButton onClick={action} aria-label="Delete" disabled={!enableIcon}>
            <DeleteIcon  />
        </IconButton>
    )
}

export default CustomDeleteIcon;