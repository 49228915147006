import Grid from '@mui/material/Grid';
import DatePickerInput from './DatePickerInput';

function DateRangePickerInput({ startDate, endDate, setStartDate, setEndDate }) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DatePickerInput label='Start Date' value={startDate} setNewDate={setStartDate} />
            </Grid>
            <Grid item xs={6}>
                <DatePickerInput label='End Date' value={endDate} setNewDate={setEndDate} />
            </Grid>
        </Grid>  
    )

}

export default DateRangePickerInput;