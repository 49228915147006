import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";
import PaginationControlled from "components/input/raw_inputs/PaginationControlled";
import SelectInput from "components/input/raw_inputs/SelectInput";
import TextFieldInput from "components/input/raw_inputs/TextFieldInput";

function ClientFilter({ submit, maxPageCount=10 }){

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [status, setStatus] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const pressSubmit = () => {
        const filter = {
            name: name,
            email: email,
            organization: organization,
            status: status,
            page_number: pageNumber,
        };
        submit(filter);
    };

    const clear = () => {
        setName('');
        setEmail('');
        setOrganization('');
        setStatus(true);
        setPageNumber(1);
        const filter = {
            name: '',
            email: '',
            organization: '',
            status: '',
            page_number: 1,
        };
        submit(filter);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextFieldInput label='name' handleInput={setName} currentValue={name} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput label='email' handleInput={setEmail} currentValue={email} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput label='organization'handleInput={setOrganization} currentValue={organization} />
            </Grid>
            <Grid item xs={12}>
                <SelectInput 
                    label='status'
                    optionList={[{label:'--ALL--', value:''},{label:'Approved', value:'approved'}, {label:'Pending', value:'pending'}, {label:'Deleted', value:'deleted'}]}
                    handleInput={setStatus}
                    value={status}
                />
            </Grid>
            <Grid item xs={12}>
                <PaginationControlled
                    selectedPage={pageNumber}
                    pageCount={maxPageCount}
                    setPage={setPageNumber}
                />
            </Grid>
            
            <Grid item xs={12}>
                <TwoParallelButtons
                    label1='Submit'
                    label2='Clear'
                    action1={pressSubmit}
                    action2={clear}
                 />
            </Grid>
        </Grid>
    )

}

export default ClientFilter;