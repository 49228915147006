import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import './linechart.css';

const default_data = [{ label: 1, value: 1 }, { label: 2, value: 2 }]

function getOptions(title, xAxisLabel, yAxisLabel) {
    const default_options = {
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: 12,
                
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    minRotation: 30,
                    maxRotation:30,
                },
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: xAxisLabel,
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                },
            },
            y: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: yAxisLabel,
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                }
            }
        }
    }
    return default_options
}

const sampleMetaData = {
    chart_title: 'Single TIme Seires Chart Title',
    x_axis_label: 'x Axis Label',
    y_axis_label: 'y Axis Label'
}

function SingleTimeSeriesChart({ data = default_data, title = 'Sample Title', metadata = sampleMetaData }) {

    const [transformedObj, ] = useState(() => {
        const labels = data.map((val) => val['label']);
        const values = data.map((val) => val['value'])
        const dataObject = {
            labels: labels,
            datasets: [
                {
                    label: title,
                    data: values,
                    fill: false,
                    borderColor: 'blue',
                },
            ],
        }
        return dataObject;
    });

    const [options, ] = useState(() => {
        const opt = getOptions(title, metadata['x_axis_label'], metadata['y_axis_label'])
        return opt;
    });

    return (
        <div className="line-chart" style={{ fontFamily: 'Arial, sans-serif', fontSize:'12px'}} >
            <Line data={transformedObj} options={options} />
        </div>
    );
}

export default SingleTimeSeriesChart;
