import { Route } from 'react-router-dom';
import SampleParent from './SampleParent';

import CmeFuturesWeeklyTable from './cme_futures/CmeFuturesWeeklyTable';
import CmeFuturesWeeklyChart from './cme_futures/CmeFuturesWeeklyChart';
import CmeFuturesDailyTable from './cme_futures/CmeFuturesDailyTable';
import CmeFuturesDailyChart from './cme_futures/CmeFuturesDailyChart';


export const SampleRouter = 
    <Route path='sample/' key={1} >
        <Route index element = { <SampleParent /> } />
        <Route path='cmefutures/'>
            <Route path='daily/table/' element={ <CmeFuturesDailyTable />} />
            <Route path='daily/chart/' element={ <CmeFuturesDailyChart />} />
            <Route path='weekly/table/' element={ <CmeFuturesWeeklyTable />} />
            <Route path='weekly/chart/' element={ <CmeFuturesWeeklyChart />} />
        </Route>
    </Route>