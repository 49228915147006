import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

import SelectInput from 'components/input/raw_inputs/SelectInput';

function CommodityReportTypeSelector({ commodityName, reportType, setCommodity, setReportType }) {

    const [reportMapContainer, setReportMapContainer] = useState({
        reportMap: {
            '--SELECT REPORT--': {
                reports: ['--SELECT REPORT TYPE--']
            },
        },
        commodityList: [],
    });
    const [reportTypeList, setReportTypeList] = useState([]);

    // FETCH REPORT MAP
    const fetchReportMap = () => {
        const token = localStorage.getItem('token')
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/reportmap`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    const reportMap = r.data;
                    const commodityList = Object.keys(r.data).map(comm => ({ label: comm, value: comm }));
                    const obj = { reportMap, commodityList };
                    setReportMapContainer(obj)
                } else {
                    alert('Unable to fetch report map');
                }
            })
    }

    const updateCommodityFunc = (commodityName) => {
        const reportMap = reportMapContainer['reportMap'];
        const reportTypeList = reportMap[commodityName]['reports'].map(reportType => ({ label: reportType, value: reportType }));
        setReportTypeList(reportTypeList);
        setCommodity(commodityName);
    };

    useEffect(() => {
        fetchReportMap();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="init-report-section-core-input">
                <SelectInput
                    label="Commodity Name"
                    optionList={reportMapContainer.commodityList}
                    helperText="*Mandatory"
                    handleInput={updateCommodityFunc}
                    value={commodityName}
                />
            </Grid>
            <Grid item xs={12} className="init-report-section-core-input">
                <SelectInput
                    label="Report Type"
                    optionList={reportTypeList}
                    helperText="*Mandatory"
                    handleInput={setReportType}
                    value={reportType}
                />
            </Grid>
        </Grid>
    )

}

export default CommodityReportTypeSelector;