import Grid from '@mui/material/Grid';
import weeklyChart from './spot_price/weeklyChart.json';
import ChartMapper from 'components/charts/ChartMapper';

export default function CmeFuturesWeeklyChart(){
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Weekly Chart</h3>
            </Grid>
            <Grid item xs={12}>
                <ChartMapper type={weeklyChart['data']['type']} info={weeklyChart['data']['info']['data']} />
            </Grid>
        </Grid>
    )
    
}