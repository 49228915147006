import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";
import ApprovalBtn from './ApprovalBtn';
import Button from '@mui/material/Button';
import TablePreview from 'components/tables/TablePreview';
import ChartPreview from 'components/charts/ChartPreview';
import HeaderFooterSection from './HeaderFooterSection';
import PageBreakdownManager from '../../PageBreakdownManager';

const defaultVisualData = {
    visualization_data: {
        'type': '',
        'info': {},
        'metadata': {
            chart_title: 'Sample Title',
            x_axis_label: 'x-axis label',
            y_axis_label: 'y-axis label'
        }
    },
    'approval_status': 'pending',
    'visualization_header': '',
    'visualization_footer': ''
}

function VisualizationTabNew({ sectionId, sectionUpdateFlag, visualizationData = defaultVisualData, visualizationType = 'vis_chart', pageBreakStatus=false}) {

    const [response, setResponse] = useState(() => (visualizationData == null || visualizationData == undefined) ? defaultVisualData : visualizationData)
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    }
    const openModal = () => {
        setModalOpen(true);
    }

    useEffect(() => {
        if (visualizationData == null || visualizationData == undefined || Object.keys(visualizationData) == 0) {
            setResponse(defaultVisualData)
        } else {
            setResponse(visualizationData)
        }
    }, [sectionUpdateFlag])

    const fetchData = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/${visualizationType}`;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
            }
        }).then(r => r.json())
            .then(res => {
                if (res.success == true) {
                    setResponse(res.data);
                } else {
                    alert(res.data);
                }
            })
    }

    const deleteData = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/${visualizationType}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: authToken,
            }
        }).then(r => r.json())
            .then(res => {
                if (res.success == true) {
                    alert('Visualization Delete successfully');
                    setResponse(defaultVisualData)
                } else {
                    alert(res.data);
                }
            })
    }

    return (
        <Grid container spacing={1}>
            {response !== null ? 
                <Grid item xs={12}>
                    {visualizationType == 'vis_chart' ? <ChartPreview dataObject={response} /> : 
                    <TablePreview 
                        dataObject={response} 
                        editFlag={true} 
                        sectionId={sectionId} 
                        tableType='vis_table'
                        breakPoint={response['table_breakpoint']}
                    />
                    }
                </Grid> :
                <Grid item xs={12}></Grid>
            }
            <Grid item xs={6}>
                <TwoParallelButtons
                    label1='Fetch Visualization'
                    label2='Delete Visualization'
                    action1={fetchData}
                    action2={deleteData}
                />
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <ApprovalBtn
                            sectionId={sectionId}
                            approvalStatus={visualizationData['approval_status']}
                            visualizationType={visualizationType}
                            sectionUpdateFlag={sectionUpdateFlag}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PageBreakdownManager
                            sectionId={sectionId}
                            label={(visualizationType=='vis_chart' ? 'Chart Page Break' : 'Table Page Break')}
                            defaultState = {pageBreakStatus}
                            flag={visualizationType}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Button variant='contained' onClick={() => openModal()}>
                    OpenModal
                </Button>
            </Grid>
            <HeaderFooterSection
                sectionId={sectionId}
                showModal={modalOpen}
                handleClose={handleClose}
            />
        </Grid>
    )
}

export default VisualizationTabNew