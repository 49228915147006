import { useState, useEffect } from 'react';
// import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import ArchiveTable from "./ArchiveTable";
import Divider from "@mui/material/Divider";
import ArchiveFilter from "./archive_filter/ArchiveFilter";

function ReportArchiveScreen() {

    const [filter, setFilter] = useState({
        commodity_name: '',
        report_type: '',
        medium:'',
        page_number: 1,
        start_date: '',
        end_date: new Date()
    });
    const [response, setResponse] = useState({"max_page_count":10, "report_data":[]});

    // FETCH REPORTS
    const fetchReports = () => {
        const token = localStorage.getItem('token');
        let startDate = '';
        if (filter['start_date'] !== ''){
            startDate = moment(filter['start_date']).format('YYYY-MM-DD');
        }
        const endDate = moment(filter['end_date']).format('YYYY-MM-DD');
        const baseUrl = `${process.env.REACT_APP_SERVER_URL}/report/list?commodity_name=${filter['commodity_name']}&report_type=${filter['report_type']}&medium=${filter['medium']}&page_number=${filter['page_number']}&start_date=${startDate}&end_date=${endDate}`;
        fetch(baseUrl, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        }
        ).then(r => r.json())
            .then(res => {
                if (res.success == true) {
                    setResponse(res.data);
                } else {
                    alert(JSON.stringify(res));
                }
            })
    }

    useEffect(() => {
        fetchReports()
    }, [filter]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
            <Divider>
                <h3>Review Reports</h3>
            </Divider>
            </Grid>
            <Grid item xs={3}>
                <ArchiveFilter setFilter={setFilter} maxPageCount={response['max_page_count']} />
            </Grid>
            <Grid item xs={9}>
                <div>
                    <ArchiveTable reportList={response['report_data']} />
                </div>
            </Grid>
        </Grid>
    )

}

export default ReportArchiveScreen;