import { Modal, } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImagePreview from 'components/ImagePreview';
import DataField from '../headerFooterBox/DataField';
import TablePreview from 'components/tables/TablePreview';

const defaultVisualData = {
    visualization_data:{
        'type':'',
        'info':{}
    },
    'approval_status':'pending',
    'visualization_header':'',
    'visualization_footer':''
}


function PreviewModal({ sectionId, modalStatus, handleClose, contentType, content = defaultVisualData }) {

    const [reactElement, setReactElement] = useState(<></>);

    useEffect(() => {
        let element = <></>;
        if (contentType == 'excel') {
            element = <TablePreview 
                dataObject={content} 
                editFlag={true} 
                sectionId={sectionId} 
                tableType='upload_table' 
                breakPoint= {content['table_breakpoint']}
            />
        } else if (contentType == 'image'){
            element = <ImagePreview dataObject={content} />
        }
        setReactElement(element);
    }, [contentType, content]);

    return (
        <Modal show={modalStatus} onHide={handleClose} size='lg'>
            <Modal.Header>
                Content Preview
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {reactElement}
                    </Grid>
                    <Grid item xs={12}>
                        <DataField defaultValue={content['visualization_header']} sectionId={sectionId} label='Edit Header' type='header' field={`${contentType}`} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataField defaultValue={content['visualization_footer']} sectionId={sectionId} label='Edit Footer' type='footer' field={`${contentType}`} />
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ float: 'right' }}>
                    <Button variant="contained" onClick={() => handleClose()} color="success">
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default PreviewModal;