// import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from "uuid";
import SingleTimeSeriesChart from 'components/charts/line_chart/SingleTimeSeriesChart';

function SingleLineSubplots({ metadata, dataObject = {} }){

    if (Object.keys(dataObject).length > 0){
            const numCharts = Object.keys(dataObject).length;
            const gridWidth = numCharts === 1 ? 12 : 6;
        return (
            <Grid container>
                {Object.keys(dataObject).map((title) => (
                    <Grid item xs={gridWidth} key={uuidv4()}  style={{ padding: '15px' }}>
                        <SingleTimeSeriesChart title={title} data={dataObject[title]} metadata={metadata} />
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return <></>
    }
}
export default SingleLineSubplots;