import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { Link } from 'react-router-dom';

function PrimarySidebar({ collapseSidebar=false }){

    return (
        <Sidebar collapsed={collapseSidebar} breakpoint='md'>
            <Menu>
                <SubMenu label="Clients">
                    <MenuItem component={<Link to='/dashboard/client/create' />}> New Client </MenuItem>
                    <MenuItem component={<Link to='/dashboard/client/edit' />}> Edit Client </MenuItem>
                    <MenuItem component={<Link to='/dashboard/client/subscriptions' />}> Subscriptions </MenuItem>
                </SubMenu>
                <SubMenu label='Reports'>
                    <MenuItem component={<Link to='/dashboard/reports/create' />} > New Report </MenuItem>
                    <MenuItem component={<Link to='/dashboard/reports/archive' />} > Report Archives </MenuItem>
                    <MenuItem component={<Link to='/dashboard/reports/show' />}> Show Report Tab </MenuItem>
                </SubMenu>
                <SubMenu label='CME'>
                    <MenuItem component={<Link to='/dashboard/sample/cmefutures/daily/chart' />}> Daily Chart </MenuItem>
                    <MenuItem component={<Link to='/dashboard/sample/cmefutures/daily/table' />}> Daily Table </MenuItem>
                    <MenuItem component={<Link to='/dashboard/sample/cmefutures/weekly/chart' />}> Weekly Chart </MenuItem>
                    <MenuItem component={<Link to='/dashboard/sample/cmefutures/weekly/table' />}> Weekly Table</MenuItem>
                </SubMenu>
                <SubMenu label="Logout">
                    <MenuItem component={<Link to='/dashboard/logout' />}> Logout </MenuItem>
                </SubMenu>
            </Menu>
        </Sidebar>
    )
}

export default PrimarySidebar;