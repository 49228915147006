import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import './report_preview.css';
import ReportSection from './section/ReportSection';

function SectionPreview({ sectionId = 20}){

    const [sectionObj, setSectionObj] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
            method:'GET',
            headers:{
                Authorization:token
            }
        })
        .then(r => r.json())
        .then(r => {
            if(r.success === true){
                setSectionObj(r.data);
            } else {
                alert('Error Fetching Report')
            }
        })
    }, []);

    if(Object.keys(sectionObj).length > 0){
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportSection className='report_container' sectionObj={sectionObj} />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>WAITING FOR REPORT</h3>
                </Grid>
            </Grid>
        )
    }
}

export default SectionPreview;