export const sampleData = [
    {
        "label": "2022-04-01",
        "value": 100.0
    },
    {
        "label": "2022-04-02",
        "value": 100.0
    },
    {
        "label": "2022-04-03",
        "value": 100.0
    },
    {
        "label": "2022-04-04",
        "value": 99.3
    },
    {
        "label": "2022-04-05",
        "value": 99.1
    },
    {
        "label": "2022-04-06",
        "value": 99.1
    },
    {
        "label": "2022-04-07",
        "value": 98.5
    },
    {
        "label": "2022-04-08",
        "value": 98.7
    },
    {
        "label": "2022-04-09",
        "value": 98.7
    },
    {
        "label": "2022-04-10",
        "value": 98.7
    },
    {
        "label": "2022-04-11",
        "value": 98.2
    },
    {
        "label": "2022-04-12",
        "value": 98.3
    },
    {
        "label": "2022-04-13",
        "value": 98.4
    },
    {
        "label": "2022-04-14",
        "value": 98.4
    },
    {
        "label": "2022-04-15",
        "value": 98.4
    },
    {
        "label": "2022-04-16",
        "value": 98.4
    },
    {
        "label": "2022-04-17",
        "value": 98.4
    },
    {
        "label": "2022-04-18",
        "value": 100.9
    },
    {
        "label": "2022-04-19",
        "value": 102.2
    },
    {
        "label": "2022-04-20",
        "value": 102.3
    },
    {
        "label": "2022-04-21",
        "value": 101.5
    },
    {
        "label": "2022-04-22",
        "value": 102.2
    },
    {
        "label": "2022-04-23",
        "value": 102.2
    },
    {
        "label": "2022-04-24",
        "value": 102.2
    },
    {
        "label": "2022-04-25",
        "value": 103.2
    },
    {
        "label": "2022-04-26",
        "value": 103.2
    },
    {
        "label": "2022-04-27",
        "value": 103.6
    },
    {
        "label": "2022-04-28",
        "value": 104.3
    },
    {
        "label": "2022-04-29",
        "value": 104.8
    },
    {
        "label": "2022-04-30",
        "value": 104.8
    },
    {
        "label": "2022-05-01",
        "value": 104.8
    },
    {
        "label": "2022-05-02",
        "value": 101.9
    },
    {
        "label": "2022-05-03",
        "value": 101.9
    },
    {
        "label": "2022-05-04",
        "value": 103.5
    },
    {
        "label": "2022-05-05",
        "value": 103.7
    },
    {
        "label": "2022-05-06",
        "value": 103.9
    },
    {
        "label": "2022-05-07",
        "value": 103.9
    },
    {
        "label": "2022-05-08",
        "value": 103.9
    },
    {
        "label": "2022-05-09",
        "value": 104.1
    },
    {
        "label": "2022-05-10",
        "value": 104.7
    },
    {
        "label": "2022-05-11",
        "value": 103.7
    },
    {
        "label": "2022-05-12",
        "value": 104.0
    },
    {
        "label": "2022-05-13",
        "value": 104.8
    },
    {
        "label": "2022-05-14",
        "value": 104.8
    },
    {
        "label": "2022-05-15",
        "value": 104.8
    },
    {
        "label": "2022-05-16",
        "value": 100.3
    },
    {
        "label": "2022-05-17",
        "value": 100.0
    },
    {
        "label": "2022-05-18",
        "value": 100.4
    },
    {
        "label": "2022-05-19",
        "value": 99.6
    },
    {
        "label": "2022-05-20",
        "value": 100.2
    },
    {
        "label": "2022-05-21",
        "value": 100.2
    },
    {
        "label": "2022-05-22",
        "value": 100.2
    },
    {
        "label": "2022-05-23",
        "value": 99.3
    },
    {
        "label": "2022-05-24",
        "value": 98.5
    },
    {
        "label": "2022-05-25",
        "value": 98.1
    },
    {
        "label": "2022-05-26",
        "value": 98.2
    },
    {
        "label": "2022-05-27",
        "value": 97.9
    },
    {
        "label": "2022-05-28",
        "value": 97.9
    },
    {
        "label": "2022-05-29",
        "value": 97.9
    },
    {
        "label": "2022-05-30",
        "value": 97.9
    },
    {
        "label": "2022-05-31",
        "value": 97.9
    },
    {
        "label": "2022-06-01",
        "value": 98.0
    },
    {
        "label": "2022-06-02",
        "value": 97.9
    },
    {
        "label": "2022-06-03",
        "value": 98.2
    },
    {
        "label": "2022-06-04",
        "value": 98.2
    },
    {
        "label": "2022-06-05",
        "value": 98.2
    },
    {
        "label": "2022-06-06",
        "value": 98.2
    },
    {
        "label": "2022-06-07",
        "value": 97.1
    }
]