import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Grid from "@mui/material/Grid";

// import VisualizationTab from "./VisualizationTab";
import VisualizationTabNew from "./vis_tab/VisualizationTabNew";
import FormMapper from "./forms/FormMapper";

function VisualTabSection({ sectionId, sectionObject, sectionUpdateFlag }) {
  
  const [tabValue, setTabValue] = useState("Table");

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          id="controlled-tab-example"
          activeKey={tabValue}
          onSelect={(k) => setTabValue(k)}
          className="mb-3"
        >
          <Tab eventKey="Table" title="Table">
            <VisualizationTabNew
              sectionId={sectionId} 
              visualizationType='vis_table' 
              visualizationData = {sectionObject['vis_table_content']}
              sectionUpdateFlag = {sectionUpdateFlag}
              pageBreakStatus={sectionObject['vis_table_content']['table_page_break'] || false}
            />
          </Tab>
          <Tab eventKey="Chart" title="chart">
            <VisualizationTabNew
              sectionId={sectionId} 
              visualizationType="vis_chart"
              visualizationData = {sectionObject['vis_chart_content']}
              sectionUpdateFlag = {sectionUpdateFlag}
              pageBreakStatus={sectionObject['vis_chart_content']['chart_page_break'] || false}
            />
          </Tab>
          <Tab eventKey='Form' title='form'>
            <FormMapper sectionObj={sectionObject} />
          </Tab>
        </Tabs>
      </Grid>
    </Grid>
  );
}

export default VisualTabSection;
