import Button from "@mui/material/Button";
// import { v4 as uuidv4 } from 'uuid';

function UploadFileButton({ label = "Upload File Here", fileTypeList=[".pdf"], setFile=()=>{}, resetKey=0 }) {

  const fileHandler = event => {
    const file = event.target.files[0]
    setFile(file);
  }

  return (
    <div style={{display:'inline'}}>
      <b>{label}</b>
      <Button variant="contained">
        <input key={resetKey} type="file" accept={fileTypeList} onChange={fileHandler} />
      </Button>
      
    </div>
  );
}

export default UploadFileButton;
