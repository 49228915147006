import Grid from '@mui/material/Grid';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';
import { useState, useEffect } from 'react';

function DataField({ label, sectionId, type='header', field='excel', defaultValue='' }){
    
    const [data, setData] = useState(defaultValue);
    
    useEffect(() => {
        setData(defaultValue);
    }, [sectionId]);

    const submit = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData()
        formData.append('value', data);
        formData.append('type', type);
        formData.append('field', field);
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/metacontent`, {
            method:'PUT',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('Value update succesfully');
            } else {
                alert('Unable to update value');
            }
        })
    }

    const clear = () => {
        setData('');
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h5>{label}</h5>
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput currentValue={data} handleInput={setData} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='Submit' label2='Clear' action1={submit} action2={clear} />
            </Grid>
        </Grid>
    )
}

export default DataField;