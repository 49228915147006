import Grid from '@mui/material/Grid';
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function CreateCrushMarginForm({ sectionId }){

    const createCrushMargin = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData()
        formData.append('section_id', sectionId);
        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/crushmargin/`, {
            method:'POST',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert(r.data);
            } else {
                alert(r.data);
            }
        })
    }

    const deleteCrushMargin = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData()
        formData.append('section_id', sectionId);
        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/crushmargin/`, {
            method:'DELETE',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert(r.data);
            } else {
                alert(r.data);
            }
        })
    }


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h3>CREATE/DELETE CRUSH MARGIN DATA</h3>
            </Grid>
            <Grid item xs={12}>
                Section Id: {sectionId}
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='Create' label2='Delete' action1={createCrushMargin} action2={deleteCrushMargin} />
            </Grid>
        </Grid>
    )

}

export default CreateCrushMarginForm;