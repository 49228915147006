import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import { v4 as uuidv4 } from "uuid";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './client_email_logs.css';
import moment from 'moment';
import PaginationControlled from 'components/input/raw_inputs/PaginationControlled';

function ClientEmailLogs({ clientId }){

    const [pageNumber, setPageNumber] = useState(1);
    const [emailLogList, setEmailLogList] = useState([]);

    const fetchClientLogs = () => {
        const token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/client/${clientId}/emaillogs/${pageNumber}`;
        fetch(url,{
            headers:{
                Authorization:token,
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setEmailLogList(r.data);
            } else {
                alert('Unable to fetch email logs');
            }
        })
    }

    useEffect(() => {
        fetchClientLogs();
    }, [clientId, pageNumber]);

    return (
        <div>
            <TableContainer component={Paper} className='div-scrollable'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">MessageId</TableCell>
                            <TableCell align="center">Subject</TableCell>
                            <TableCell align="center">Publish Date</TableCell>
                            <TableCell align="center">Email Time</TableCell>
                            <TableCell align="center">Sender</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailLogList.map((log) => (
                            <TableRow key={uuidv4()}>
                                <TableCell>{log.message_id}</TableCell>
                                <TableCell>{log.subject}</TableCell>
                                <TableCell>{moment(log.publish_date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{log.email_time}</TableCell>
                                <TableCell>{log.sender}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControlled selectedPage={pageNumber} pageCount={10} setPage={setPageNumber} />
        </div>
    )

    
}

export default ClientEmailLogs;