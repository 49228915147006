import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PreviewModal from './PreviewModal';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

import UploadFileButton from "components/input/raw_inputs/UploadFileButton";

import "./file_input_container.css";

const defaultVisualData = {
  visualization_data:{
      'type':'',
      'info':{}
  },
  'approval_status':'pending',
  'visualization_header':'',
  'visualization_footer':''
}

function FileInputContainer({ sectionUpdateFlag, keyName, ButtonLabel = "Upload Default File", fileType = 'image', sectionId = 1, approvalStatus = null }) {

  const [fileName, setFileName] = useState(keyName);
  const [file, setFile] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [checked, setChecked] = useState(() => (approvalStatus == 'approved') ? true : false);
  const [openModal, setOpenModal] = useState(false);
  const [fileContent, setFileContent] = useState(defaultVisualData);

  const closeModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    setFile(null);
    setFileName(keyName);
    setResetKey(0);
    setChecked(() => (approvalStatus == 'approved') ? true : false);
  }, [sectionUpdateFlag]);

  let fileTypeList = [];
  let download_file_url = '';
  let upload_file_url = '';
  let delete_file_url = '';
  let fetch_file_url = '';
  let approve_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`;

  if (fileType == 'image') {
    fileTypeList = ['.png', '.jpg', '.jpeg'];
    upload_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/upload_image`;
    download_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/get_image`;
    fetch_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/image_content`;
    delete_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/delete_image`;
  } else if (fileType == 'excel') {
    fileTypeList = ['.csv'];
    upload_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/upload_excel`;
    download_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/get_excel`;
    fetch_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/excel_content`;
    delete_file_url = `${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/delete_excel`;
  }

  const changeUploadResetKey = () => {
    let currentKey = resetKey;
    currentKey = currentKey + 1
    setResetKey(currentKey);
  }

  const uploadFile = () => {
    // Upload File
    if (file == null) {
      alert('Choose file first');
      return;
    }
    const authToken = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
    fetch(upload_file_url, {
      method: 'POST',
      headers: {
        Authorization: authToken
      },
      body: formData
    })
      .then(r => r.json())
      .then(res => {
        if (res.success == true) {
          setFileName(res.data['key_name'])
          alert('File uploaded successfully')
        } else if (res.success == false) {
          alert(JSON.stringify(res.data));
        }
      });
    changeUploadResetKey();
  }

  const handleChange = (event) => {
    if (event.target.checked == 1) {
      approveFile('approved');
    } else {
      approveFile('pending');
    }
  };

  const approveFile = (status) => {
    const authToken = localStorage.getItem('token');
    let requestBody = {};
    if (fileType == 'image') {
      requestBody = {
        upload_image_approval_status: status
      };
    } else if (fileType == 'excel') {
      requestBody = {
        upload_excel_approval_status: status
      };
    }
    fetch(approve_file_url, {
      method: 'PUT',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(r => r.json())
      .then(res => {
        if (res.success == true) {
          alert(res.data);
          if (status == 'approved') {
            setChecked(true);
          } else {
            setChecked(false);
          }
        } else if (res.success == false) {
          alert(JSON.stringify(res.data));
        }
      })
  }

  const deleteFile = () => {
    const authToken = localStorage.getItem('token');
    fetch(delete_file_url, {
      method: 'DELETE',
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json'
      }
    })
      .then(r => r.json())
      .then(res => {
        if (res.success == true) {
          alert(res.data);
          setFileName('');
          setChecked(false);
        } else if (res.success == false) {
          alert(JSON.stringify(res.data));
        }
      });
    setFile(null);
    changeUploadResetKey();
  }

  const downloadFile = () => {
    if (fileName == null || fileName == '') {
      alert('File Not Present');
      return;
    }
    const authToken = localStorage.getItem('token');
    fetch(download_file_url, {
      method:'GET',
      headers:{
        Authorization:authToken
      }
    }).then(r => r.json())
    .then(r => {
      if(r.success == true){
        window.open(r.data, '_blank');
      } else {
        alert('Unable to download file');
      }
    })
  }

  const openFile = () => {
    if (fileType == 'image' && (fileName == null || fileName == '')) {
      alert('File Not Present');
      return;
    }
    const authToken = localStorage.getItem('token');
    fetch(fetch_file_url, {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    })
      .then(r => r.json())
      .then(res => {
        if (res.success == true) {
          if (fileType == 'image' || fileType == 'excel') {
            setFileContent(res.data);
            setOpenModal(true);
          } else {
            alert('Incorrect File Type')
          }
        } else if (res.success == false) {
          alert(res.data);
        }
      })
  }

  return (
    <div>
      <Box className="file-input-container-box">
        <Grid container>
          <Grid item xs={12}>
            <span>
              <b>{ButtonLabel}</b>
            </span>
          </Grid>
          <Grid item xs={12} style={{ fontSize: '20px', fontWeight: 'bold', color: 'blue' }}>
            <u onClick={() => downloadFile()}>{fileName}</u>
          </Grid>
          <Grid item>
            <UploadFileButton label={''} fileTypeList={fileTypeList} setFile={setFile} resetKey={resetKey} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  value={checked}
                  control={<Checkbox checked={checked} onChange={handleChange} />}
                  label="File Required"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ paddingTop: "1vh" }} >
                  <Typography>
                    Delete File : <DeleteOutlinedIcon onClick={() => deleteFile()} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TwoParallelButtons label1='Upload/Modify' label2='Open File' action1={uploadFile} action2={openFile} />
          </Grid>
        </Grid>
      </Box>
      <PreviewModal 
        sectionId={sectionId} 
        modalStatus={openModal} 
        handleClose={closeModal} 
        content={fileContent} 
        contentType={fileType} 
      />
    </div>
  );
}

export default FileInputContainer;
