import SingleLineSubplots from "./visuals/SingleLineSubpots";
import MultiLineTimeSeries from "./visuals/MultiLineTimeseries";
import BarLineComboPlot from "./visuals/BarLineComboPlot";
import MultipleBarLineCombo from "./bar_chart/MultipleBarLIneCombo";
import SingleTimeSeriesMonthlyAxis from "./line_chart/SingleTimeSeriesMonthlyAxis";
import TwoAxesChart from "./visuals/TwoAxesChart";

const sampleMetaData = {
    chart_title:'Sample Title',
    x_axis_label:'x-axis label',
    y_axis_label:'y-axis label'
}

function ChartMapper({ type = '', info = {}, metadata=sampleMetaData }){

    if(type == 'single_line_subplots'){
        return <SingleLineSubplots dataObject={info} metadata={metadata} />
    } else if (type =='multi_line_timeseries'){
        return <MultiLineTimeSeries dataObject={info} metadata={metadata} />
    }
    else if(type =='bar_line_combo'){
        return <BarLineComboPlot dataObject={info} metadata={metadata} />
    }
    else if(type == 'multiple_line_bar_combo'){
        return <MultipleBarLineCombo dataObject={info} />
    }
    else if(type == 'single_time_series_monthly'){
        return <SingleTimeSeriesMonthlyAxis dataObject={info} metadata={metadata} />
    }
    else if(type == 'two_axes_chart'){
        return <TwoAxesChart dataObject={info} metadata={metadata} />
    }
    else {
        return <></>
    }

}

export default ChartMapper;