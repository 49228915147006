import { useEffect } from 'react';
import { useState } from 'react';

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

import FileInputContainer from "./file_input_container/FileInputContainer";
import DraftInputContainer from "./draft_input_container/DraftInputContainer";
import HeadingInputContainer from "./heading_input_container/HeadingInputContainer";
import VisualTabSection from "./visualization_tab/VisualTabSection";
import SectionMetadata from "./section_metadata/SectionMetadata";
import PageBreakdownManager from './PageBreakdownManager';

function FactorDetailScreen({ sectionId }) {

  const [sectionObject, setSectionObject] = useState({});
  const [sectionUpdateFlag, setSectionUpdateFlag] = useState(0);

  const fetchSectionDetails = () => {
    const token = localStorage.getItem('token');
    fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    })
      .then(r => r.json())
      .then(r => {
        if (r.success == true) {
          r.data['publish_date'] = new Date(r.data['publish_date']);
          r.data['section_id'] = r.data['id'];
          setSectionObject(r.data);
          const flagVal = sectionUpdateFlag;
          setSectionUpdateFlag(flagVal+1);
        } else {
          alert('Error Fetch Section Object');
        }

      });
  }

  useEffect(() => {
    fetchSectionDetails();
  }, [sectionId]);

  if (Object.keys(sectionObject).length == 0) {
    return (
      <Grid container spacing={2}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2> {sectionObject.section_name} </h2>
        </Grid>
        <Grid item xs={12}>
          <SectionMetadata
            factorName={sectionObject.section_name}
            commodityName={sectionObject.commodity_name}
            publishDate={sectionObject.publish_date}
            reportType={sectionObject.report_type}
            sectionId = {sectionObject.section_id}
            fetchSectionFunc = {fetchSectionDetails}
            sectionUpdateFlag = {sectionUpdateFlag}
          />
        </Grid>
        <Grid item xs={12}>
          <HeadingInputContainer
            sectionId={sectionObject.section_id}
            currentHeading={sectionObject.section_heading}
            sectionUpdateFlag = {sectionUpdateFlag}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider> <h4>Visualization Section</h4> </Divider>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <VisualTabSection
                sectionId={sectionObject.section_id} 
                sectionObject={sectionObject} 
                sectionUpdateFlag = {sectionUpdateFlag}
              />
            </Grid>
            <Grid item xs={12}>
            <Divider> <h4>File Upload Section</h4> </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div>
                    <h5>Custom File Upload</h5>
                    <FileInputContainer
                      ButtonLabel="Upload Excel File"
                      sectionId={sectionObject.section_id}
                      fileType='excel'
                      keyName={sectionObject['upload_excel_content']['file_name']}
                      approvalStatus={sectionObject['upload_excel_content']['approval_status']}
                      sectionUpdateFlag = {sectionUpdateFlag}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <h5>Image Upload</h5>
                    <FileInputContainer
                      ButtonLabel="Upload Image File"
                      sectionId={sectionObject.section_id}
                      fileType='image'
                      keyName={sectionObject['upload_image_content']['s3_key']}
                      approvalStatus={sectionObject['upload_image_content']['approval_status']}
                      sectionUpdateFlag = {sectionUpdateFlag}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider> <h4>Detailed Draft</h4> </Divider>
          <DraftInputContainer sectionId={sectionObject.section_id} defaultContent={sectionObject.visual_draft_content} />
        </Grid>
        <Grid item xs={12}>
          <PageBreakdownManager sectionId={sectionObject.section_id} defaultState={sectionObject['page_break_flag']} />
        </Grid>
      </Grid>
    );
  }

}

export default FactorDetailScreen;
