import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ImportSectionDataModal from './ImportSectionDataModal';

function SectionMetadata({ factorName, commodityName, publishDate, reportType, sectionId, sectionUpdateFlag, fetchSectionFunc = () => {} }) {

  const [modalStatus, setModalStatus] = useState(false);
  const openEditModal = () => {
      setModalStatus(true);
  }

  const handleClose = () => {
      setModalStatus(false);
      fetchSectionFunc();
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        Commodity: <b>{commodityName}</b>
      </Grid>
      <Grid item>
        {/* Date: <b>{formattedDate}</b> */}
        Date: <b>{publishDate.toLocaleDateString()}</b>
      </Grid>
      <Grid item>
        Type: <b>{reportType}</b>
      </Grid>
      <Grid item>
        Factor: <b>{factorName}</b>
      </Grid>
      <Grid item>
        Section Id : <b>{sectionId}</b>
      </Grid>
      <Grid item >
        <div style={{float:'right'}}>
          <Button variant="contained" color="success" onClick={() => openEditModal()}> Import Data </Button>
        </div>
      </Grid>
      <ImportSectionDataModal
        sectionId={sectionId} 
        handleClose={handleClose} 
        modalStatus={modalStatus} 
        sectionUpdateFlag={sectionUpdateFlag}
      />
    </Grid>
  )
}

export default SectionMetadata;