import { Route } from 'react-router-dom';
import NewClient from './clients/create_client/NewClient';
import EditClient from './clients/edit_client/EditClient';
import SubscriptionScreen from './subscriptions/SubscriptionScreen';


export const CustomerRouter = 
    <Route path='client/' key={3}>
        <Route index element={<NewClient />} />
        <Route path='create/' element={<NewClient />} />
        <Route path='edit/' element={<EditClient />} />
        <Route path='subscriptions/' element={<SubscriptionScreen />} />
    </Route>