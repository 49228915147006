import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Typography, Grid } from "@mui/material";
import AddLabelComponentTwo from './AddLabelComponentTwo';
import ReportFactorCheckboxes from "./ReportFactorCheckboxes";

function SetReportFactors({initCheckboxList = [], saveFactors=()=>{}}) {
  
  const [checkboxList, setCheckboxList] = useState(initCheckboxList);

  useEffect(() => {
    setCheckboxList(initCheckboxList);
  }, [initCheckboxList]);

  const addLabel = (newLabel) => {
    const cbList = [...checkboxList];
    const cbListLen = cbList.length;
    cbList.push({ 
      section_id: Math.floor((Math.random()*-100)-1), 
      section_name: newLabel, 
      section_code: uuidv4(), 
      section_delete_enable: true, 
      section_priority: cbListLen+1, 
      section_active:false 
    });
    setCheckboxList(cbList);
  };

  const removeLabel = (label) => {
    const cbList = [...checkboxList];
    const filteredArray = cbList.filter((cb) => cb.section_name !== label);
    setCheckboxList(filteredArray);
  };

  if (checkboxList.length > 0){
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Check/Add Required Factors:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
            <ReportFactorCheckboxes checkboxList={checkboxList} removeLabel={removeLabel} saveFactors={saveFactors} />
            </Grid>
            <Grid item xs={6}>
              <AddLabelComponentTwo addLabel={addLabel} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return <></>;
  }
}

export default SetReportFactors;
