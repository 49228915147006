import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    domestic:{
        'Delhi_Basmati':{current:61500, change:50},
        'Amritsar_Sarbati Raw':{current:61500, change:50},
        'Karnal_1121 Steam':{current:61500, change:50},
        'Nizamabad_BPT Raw':{current:61500, change:50},
        'Karnal_Sarbati Sella':{current:56500, change:500},
    },
    cbot:[{
        month:'Dec-23',
        current:591,
        change:-24
    }, 
    {
        month:'Jan-24',
        current:591,
        change:-24
    }, 
    {
        month:'Feb-24',
        current:591,
        change:-24
    }, 
    {
        month:'Mar-24',
        current:591,
        change:-24
    }],
    fob:{
        'thailand':{current:1100, change:50},
        'vietnam':{current:1200, change:50}
    },
    currency:{
        'India':{current:1100, change:50},
        'United Kingdom':{current:1100, change:50},
        'European Union':{current:1100, change:50},
        'Japan':{current:1100, change:50},
    }
}

function RiceDailySummary({ dataObject=sampleResponse }){

    return (
        <TableContainer className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Mandi Prices (INR/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Delhi (Basmati)</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi_Basmati']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi_Basmati']['change']}</TableCell>
                    </TableRow>
                    {/* <TableRow className='color-row'>
                        <TableCell>Amritsar (Sabarmati Raw)</TableCell>
                        <TableCell>{dataObject['domestic']['Amritsar_Sarbati Raw']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Amritsar_Sarbati Raw']['change']}</TableCell>
                    </TableRow> */}
                    <TableRow >
                        <TableCell>Karnal (1121 Steam)</TableCell>
                        <TableCell>{dataObject['domestic']['Karnal_1121 Steam']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Karnal_1121 Steam']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Nizamabad (BPT Raw)</TableCell>
                        <TableCell>{dataObject['domestic']['Nizamabad_BPT Raw']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Nizamabad_BPT Raw']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Karnal (Sabarmati Steam)</TableCell>
                        <TableCell>{dataObject['domestic']['Karnal_Sarbati Sella']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Karnal_Sarbati Sella']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>International Prices</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>CBOT Rough Rice (USD/100 Pounds)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][0]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{dataObject['cbot'][1]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][2]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['change']}</TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell>{dataObject['cbot'][3]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['change']}</TableCell>
                    </TableRow> */}
                    <TableRow className='color-row'>
                        <TableCell><b>FOB (USD/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Thailand</TableCell>
                        <TableCell>{dataObject['fob']['thailand']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['thailand']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Vietnam</TableCell>
                        <TableCell>{dataObject['fob']['vietnam']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['vietnam']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>India (INR)</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>United Kingdom (GBP)</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>European Union (EURO)</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Japan (JPY)</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default RiceDailySummary;