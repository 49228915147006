import { Route } from 'react-router-dom';

import ReportArchiveScreen from './report_archives/ReportArchiveScreen';
import SectionPreview from './preview/digital_report_preview/SectionPreview';
import ReportWorkflow from './automation/workflow/ReportWorkflow';
import CreateReportScreen from './create_report_screen/CreateReportScreen';
import ShowReport from './show_report/ShowReport';

export const ReportsRouter = 
    <Route path='reports/' >
        <Route index element={<CreateReportScreen />} />
        <Route path='archive/' element={ <ReportArchiveScreen /> } />
        <Route path='create/' element={ <CreateReportScreen /> } />
        <Route path='workflow/' element={ <ReportWorkflow /> } />
        <Route path='sectionPreview/' element={ <SectionPreview />} />
        <Route path='show/' element={ <ShowReport /> } />
    </Route>