import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";


function StepDefinition({
    stepIndex = 0,
    stepsLength = 0,
    stepLabel = "",
    stepContent = "",
    handleClick = () => {},
    handleNext = () => {},
    handleBack = () => {},
  }) {
    let btnGrp = (
      <TwoParallelButtons
        label1="Next"
        label2="Back"
        action1={handleNext}
        action2={handleBack}
      />
    );
  
    if (stepIndex == 0) {
      btnGrp = (
        <Button variant="contained" onClick={() => handleNext()} >
          Next
        </Button>
      );
    }

    if (stepIndex == (stepsLength-1)){
      btnGrp = (
        <Button variant="contained" onClick={() => handleBack()} color="success">
          Back
        </Button>
      );
    }
  
    return (
      <div>
        <StepLabel onClick={() => handleClick(stepIndex)}>{stepLabel}</StepLabel>
        <StepContent>
          <Typography>{stepContent}</Typography>
          {btnGrp}
        </StepContent>
      </div>
    );
  }

  export default StepDefinition;