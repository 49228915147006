import Grid from '@mui/material/Grid';
import TableMapper from 'components/tables/TableMapper';

export default function CmeFuturesDailyChart(){
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Daily Chart</h3>
            </Grid>
            <Grid item xs={12}>
                <TableMapper type='rapeseed_daily_summary' />
            </Grid>
        </Grid>
    )
}