import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './import_parity_table.css';

const sampleResponse = {
    "2023-12-04": {
        "brokerage": 0,
        "cess_duty": 0.0,
        "cess_rate": 0.0,
        "cnf_local": 90201.0,
        "cnf_usdmt": 1070.0,
        "commodity_name": "Tur",
        "date": "2023-12-04",
        "domestic_clearing_charges": 0.0,
        "domestic_freight_charge": 0.0,
        "domestic_spot_price": 92000,
        "exchange_rate": 84.3,
        "id": 5,
        "import_duty": 0.0,
        "import_duty_rate": 0.0,
        "import_parity": -1799.0,
        "port_expenses": 0,
        "port_to_mill_freight_charges": 0.0,
        "premium_rate": 0.0,
        "price_at_mill": 90201.0,
        "total_cost_port": 90201.0,
        "total_cost_usdmt": 1070.0,
        "total_domestic_price": 92000.0,
        "trade_source": "International",
        "unit": "INR per Tonne"
    },
    "2023-12-03": {
        "brokerage": 0,
        "cess_duty": 0.0,
        "cess_rate": 0.0,
        "cnf_local": 90201.0,
        "cnf_usdmt": 1070.0,
        "commodity_name": "Tur",
        "date": "2023-12-04",
        "domestic_clearing_charges": 0.0,
        "domestic_freight_charge": 0.0,
        "domestic_spot_price": 92000,
        "exchange_rate": 84.3,
        "id": 5,
        "import_duty": 0.0,
        "import_duty_rate": 0.0,
        "import_parity": -1799.0,
        "port_expenses": 0,
        "port_to_mill_freight_charges": 0.0,
        "premium_rate": 0.0,
        "price_at_mill": 90201.0,
        "total_cost_port": 90201.0,
        "total_cost_usdmt": 1070.0,
        "total_domestic_price": 92000.0,
        "trade_source": "International",
        "unit": "INR per Tonne"
    }
}

function PulsesImportParityTable({ dataObject = sampleResponse }) {
    
    if (dataObject !== null && Object.keys(dataObject).length == 2) {
        const sortedDates = Object.keys(dataObject).sort((a, b) => new Date(b) - new Date(a));

    // Ensure keyA is the latest date and keyB is the other date
        const keyA = sortedDates[0];
        const keyB = sortedDates[1];
    //  so that in parity table the latest date come on the first column and other date in the second column
        // const keyA = Object.keys(dataObject)[0]
        // const keyB = Object.keys(dataObject)[1]
        
        return (
            <TableContainer   className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
                <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                    <TableBody>
                        <TableRow className='table-header-tg'>
                            <TableCell><b>Particulars</b></TableCell>
                            <TableCell><b>{keyA}</b></TableCell>
                            <TableCell><b>{keyB}</b></TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>CNF (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['cnf_usdmt']}</TableCell>
                            <TableCell>{dataObject[keyB]['cnf_usdmt']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Import Duty</TableCell>
                            <TableCell>{dataObject[keyA]['import_duty']}</TableCell>
                            <TableCell>{dataObject[keyB]['import_duty']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>CESS</TableCell>
                            <TableCell>{dataObject[keyA]['cess_duty']}</TableCell>
                            <TableCell>{dataObject[keyB]['cess_duty']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Cost (in USD/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['total_cost_usdmt']}</TableCell>
                            <TableCell>{dataObject[keyB]['total_cost_usdmt']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>USD/INR Exchange Rate</TableCell>
                            <TableCell>{dataObject[keyA]['exchange_rate']}</TableCell>
                            <TableCell>{dataObject[keyB]['exchange_rate']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>CNF in INR (in INR/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['cnf_local']}</TableCell>
                            <TableCell>{dataObject[keyB]['cnf_local']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>Port Expenses For Container (in INR/MT) </TableCell>
                            <TableCell>{dataObject[keyA]['port_expenses']}</TableCell>
                            <TableCell>{dataObject[keyB]['port_expenses']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Brokerage (in INR/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['brokerage']}</TableCell>
                            <TableCell>{dataObject[keyB]['brokerage']}</TableCell>
                        </TableRow>
                        <TableRow className='color-row'>
                            <TableCell>Total Cost at Port (in INR/MT)</TableCell>
                            <TableCell>{dataObject[keyA]['total_cost_port']}</TableCell>
                            <TableCell>{dataObject[keyB]['total_cost_port']}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow className='color-row'>
                            <TableCell>Spot Price at Mumbai (in INR/MT) </TableCell>
                            <TableCell>{dataObject[keyA]['total_domestic_price']}</TableCell>
                            <TableCell>{dataObject[keyB]['total_domestic_price']}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>

                        <TableRow className='color-row'>
                            <TableCell>Import Parity (in INR/MT) </TableCell>
                            <TableCell>{dataObject[keyA]['import_parity']}</TableCell>
                            <TableCell>{dataObject[keyB]['import_parity']}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return <></>;
    }


}

export default PulsesImportParityTable;