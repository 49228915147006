import TablePreview from "components/tables/TablePreview";

function SectionVisualTable({ sectionObj }){

    if (sectionObj != null && Object.keys(sectionObj).length > 0 && sectionObj['vis_table_content']['approval_status'] == 'approved'){
        return (
            <div>
                <TablePreview 
                    dataObject={sectionObj['vis_table_content']} 
                    editFlag={false} 
                    breakPoint={sectionObj['vis_table_content']['table_breakpoint']}
                />
                {sectionObj['vis_table_content']['table_page_break'] ? <div className="pagebreak" /> : <></>}
            </div>
        )
    } else {
        return <></>;
    }
}

export default SectionVisualTable;