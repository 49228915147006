
import Grid from '@mui/material/Grid';
import EditReportContent from '../report_content/EditReportContent';
import EditReportFactors from "dashboard/reports/common/set_report_factors/EditReportFactors";

function EditDigitalReport({ reportDetails }){

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Edit Digital Report</h3>
            </Grid>
            <Grid item xs={12}>
                <EditReportContent reportId = {reportDetails['report_id']} />
            </Grid>
            <Grid item xs={12}>
                <EditReportFactors reportId={reportDetails['report_id']} />
            </Grid>
        </Grid>
    )
}

export default EditDigitalReport;