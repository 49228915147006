import  { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextFieldInput from "components/input/raw_inputs/TextFieldInput";

import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function HeadingInputContainer({ sectionId, currentHeading, sectionUpdateFlag }){
    const [heading, setHeading] = useState(() => (currentHeading)==null ? '' : currentHeading);
    const [saveFlag, setSaveFlag] = useState(false);

    const resetHeading = () => {
        setHeading('');
    }

    const manageHeading = (content) => {
        setHeading(content);
        if(saveFlag == true){
            setSaveFlag(false);
        }  
    }

    useEffect(()=>{
        if(currentHeading == null || currentHeading === undefined){
            setHeading('');
        } else {
            setHeading(currentHeading);
        }
        setSaveFlag(false);
    }, [sectionUpdateFlag]);

    const updateHeading = () => {
        const authToken = localStorage.getItem('token');
        let requestBody = {
            section_heading: heading
        }
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json',
                Authorization: authToken
            },
            body: JSON.stringify(requestBody)
        })
        .then(r => r.json())
        .then(res => {
            if (res.success == true){
                alert(res.data);
                setSaveFlag(true);
            } else {
                alert(JSON.stringify(res.data));
            }
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextFieldInput handleInput={manageHeading} currentValue={heading} />
            </Grid>
            <Grid item xs={6}>
                <TwoParallelButtons label1='Save' label2='Clear' action1={updateHeading} action2={resetHeading} />
            </Grid>
            <Grid item xs={6}>
                <div style={{float:'right'}}>
                    {(saveFlag)?'Saved':'Pending'}
                </div>
            </Grid>
        </Grid>
    )
}

export default HeadingInputContainer;