import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function DatePickerInput({ label='Pick a basic date', value="", setNewDate = () => {} }) {
  
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value = {dayjs(value)}
          format="YYYY-MM-DD"
          onChange={(newValue) => {
            const dt = newValue.format('YYYY-MM-DD');
            setNewDate(dt);
          }}
        />
      </LocalizationProvider>
  );
}

export default DatePickerInput;
