import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import SubscriptionTable from './SubscriptionTable';
import SubscriptionFilter from './SubscriptionFilter';
import Divider from "@mui/material/Divider";


function SubscriptionScreen(){

    const [filter, setFilter] = useState({
        commodity_name:'',
        start_date: '',
        end_date:'',
        report_type:'',
        page_number: 1,
    });

    const [response, setResponse] = useState({"max_page_count":1, "subscription_data":[]});

    const fetchSubscription = () => {
        const token = localStorage.getItem('token');
        let startDate = '';
        if (filter['start_date'] !== ''){
            startDate = moment(filter['start_date']).format('YYYY-MM-DD');
        }
        let endDate = '';
        if (filter['end_date']!==''){
            endDate = moment(filter['end_date']).format('YYYY-MM-DD');
        }
        const url = `${process.env.REACT_APP_SERVER_URL}/client/subscriptions/subs?commodity_name=${filter["commodity_name"]}&report_type=${filter["report_type"]}&page_number=${filter["page_number"]}&expiry_start=${startDate}&expiry_end=${endDate}`
        fetch(url, {
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setResponse(r.data);
            } else {
                alert('Unablet to fetch Subscription List');
            }
        });
    }

    const reset = () => {
        setFilter({
            commodity_name:'',
            start_date: '',
            end_date:'',
            report_type:'',
            page_number: 1,
        });
    }

    useEffect(() => {
        fetchSubscription();
    }, [filter]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider>
                <h3>Client Subscription</h3>
                </Divider>
            </Grid>
            <Grid item xs={3}>
                <SubscriptionFilter submit={setFilter} reset={reset} maxPageCount={response['max_page_count']} />
            </Grid>
            <Grid item xs={9}>
                <SubscriptionTable subscriptionList = {response['subscription_data']} />
            </Grid>
        </Grid>
    )

}

export default SubscriptionScreen;