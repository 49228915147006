import EditPersonalInfo from "./EditPersonalInfo";
import EditClientPassword from "./EditClientPassword";
import Grid from '@mui/material/Grid';

function EditClientInfo({ clientDetails }){

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EditPersonalInfo clientId={clientDetails['id']} />
            </Grid>
            <Grid item xs={12}>
                <EditClientPassword clientId={clientDetails['id']} />
            </Grid>
        </Grid>
    )
}

export default EditClientInfo;