import { useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import WheatImportParityModal from './WheatImportParityModal';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function WheatImportParityForm({ sectionObj }){

    const [ausCnfUsd, setAusCnfUsd] = useState('0');
    const [blackseaCnfUsd, setBlackseaCnfUsd] = useState('0');
    const [fobAus, setFobAus] = useState('0');
    const [fobBlack, setFobBlack] = useState('0');
    const [modalStatus, setModalStatus] = useState(false);
    const [parityTable, setParityTable] = useState({});

    const submitForm = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();

        formData.append('report_date', moment(sectionObj['publish_date']).format('YYYY-MM-DD'))
        formData.append('australia_cfr', ausCnfUsd);
        formData.append('blacksea_cfr', blackseaCnfUsd);
        formData.append('fob_bangalore_australia', fobAus);
        formData.append('fob_bangalore_blacksea', fobBlack)

        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/wheatparity/`, {
            method:'POST',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('parity record created');
            } else {
                alert(r.data);
            }
        })
    }

    const deleteParity = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('commodity_name', 'Wheat')
        formData.append('report_date', moment(sectionObj['publish_date']).format('YYYY-MM-DD'));
        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/wheatparity/`, {
            method:'DELETE',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('parity record deleted');
            } else {
                alert(r.data);
            }
        })
    }

    const clearForm = () => {
        setAusCnfUsd('0');
        setBlackseaCnfUsd('0');
    }

    const preview = () => {
        const token = localStorage.getItem('token');
        const dt = moment(sectionObj['publish_date']).format('YYYY-MM-DD');
        const url = `${process.env.REACT_APP_SERVER_URL}/calculations/wheatparity/?report_date=${dt}`
        fetch(url, {
            method:'GET',
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setParityTable(r.data);
                setModalStatus(true);
            } else {
                alert(r.data);
            }
        });
    }

    const handleClose = () => {
        setModalStatus(false);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h3> Wheat Import Parity Form</h3>
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Australia Wheat CFR' currentValue={ausCnfUsd} handleInput={setAusCnfUsd} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Black Sea Wheat CFR' currentValue={blackseaCnfUsd} handleInput={setBlackseaCnfUsd} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Banglore Price (Australia)' currentValue={fobAus} handleInput={setFobAus} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Banglore Price (black sea)' currentValue={fobBlack} handleInput={setFobBlack} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='submit' label2='clear' action1={submitForm} action2={clearForm} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='preview' label2='delete' action1={preview} action2={deleteParity} /> 
            </Grid>
            <WheatImportParityModal modalStatus={modalStatus} handleClose={handleClose} dataObject={parityTable} />
        </Grid> 
    )

}

export default WheatImportParityForm;