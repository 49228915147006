import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

import DateRangePickerInput from 'components/input/raw_inputs/DateRangePickerInput';
import PaginationControlled from 'components/input/raw_inputs/PaginationControlled';
import CommodityReportTypeSelector from 'dashboard/reports/common/CommodityReportTypeSelector';

function SubscriptionFilter({ submit, reset, maxPageCount=10 }){

    const [commodity, setCommodity] = useState('');
    const [reportType, setReportType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const pressSubmit = () => {
        const filter = {
            commodity_name:commodity,
            start_date: startDate,
            end_date:endDate,
            report_type:reportType,
            page_number: pageNumber,
        };
        submit(filter);
    };

    const clearForm = () => {
        setCommodity('');
        setReportType('');
        setStartDate('');
        setEndDate('');
        setPageNumber(1);
        reset();
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CommodityReportTypeSelector commodityName={commodity} setCommodity={setCommodity} reportType={reportType} setReportType={setReportType} />
            </Grid>
            <Grid item xs={12}>
                <DateRangePickerInput
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            </Grid>
            <Grid item xs={12}>
                <PaginationControlled selectedPage={pageNumber} pageCount={maxPageCount} setPage={setPageNumber} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons
                    label1='Submit Filter'
                    label2='Clear'
                    action1={pressSubmit}
                    action2={clearForm}
                />
            </Grid>
        </Grid>
    )


}

export default SubscriptionFilter;