import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import './dense_table.css';

const defaulCoulumns = ['Name', 'Age', 'City', 'Country'];

const defaultRows = [
    { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
    { name: 'Akash', age: 30, city: 'LA', country: 'USA' },
    { name: 'Deepak', age: 38, city: 'Jind', country: 'India' },
    { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function DenseTable({ columnHeaders = defaulCoulumns, columnRows = defaultRows }) {
    return (
        <TableContainer className='tableContainer' component={Paper} sx={{ minWidth: 650 }}>
            <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="a dense table"  style={{ fontFamily: 'Arial, sans-serif' }}>
                <TableBody>
                    <TableRow  style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }} >
                        {columnHeaders.map((colName) => {
                            return <TableCell align='justify' key={uuidv4()} className='table-header-tg' >
                                <h6 className='table-header-label'  style={{ fontFamily: 'Arial, sans-serif' }}>{capitalizeFirstLetter(colName)}</h6>
                            </TableCell>
                        })}
                    </TableRow>
                    {
                        columnRows.map((row, index) => {
                            return (
                                <TableRow  style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }} key={uuidv4()}>
                                    {
                                        columnHeaders.map((dictKey) => {
                                            if (index%2 == 0){
                                                return <TableCell key={uuidv4()}  className='color-row'  style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>{row[dictKey]}</TableCell>
                                            } else {
                                                return <TableCell key={uuidv4()}   style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>{row[dictKey]}</TableCell>
                                            }
                                            
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default DenseTable;


// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { v4 as uuidv4 } from 'uuid';
// import './dense_table.css';

// const defaulCoulumns = ['Name', 'Age', 'City', 'Country'];

// const defaultRows = [
//     { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
//     { name: 'Akash', age: 30, city: 'LA', country: 'USA' },
//     { name: 'Deepak', age: 38, city: 'Jind', country: 'India' },
//     { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
// ];

// function capitalizeFirstLetter(string) {
//     return string.charAt(0).toUpperCase() + string.slice(1);
// }

// function DenseTable({ columnHeaders = defaulCoulumns, columnRows = defaultRows }) {

//     return (
//         <TableContainer className='tableContainer' component={Paper} sx={{ minWidth: 650 }}>
//             <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="a dense table"  style={{ fontFamily: 'Arial, sans-serif' }}>
//                 <TableBody>
//                     <TableRow  style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }} >
//                         {columnHeaders.map((colName) => {
//                             return <TableCell align='justify' key={uuidv4()} className='table-header-tg' >
//                                 <h6 className='table-header-label'  style={{ fontFamily: 'Arial, sans-serif' }}>{capitalizeFirstLetter(colName)}</h6>
//                             </TableCell>
//                         })}
//                     </TableRow>
//                     {
//                         columnRows.map((row, index) => {
//                             return (
//                                 <TableRow  style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }} key={uuidv4()}>
//                                     {
//                                         columnHeaders.map((dictKey) => {
//                                             if (index%2 == 0){
//                                                 return <TableCell key={uuidv4()}  className='color-row'  style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>{row[dictKey]}</TableCell>
//                                             } else {
//                                                 return <TableCell key={uuidv4()}   style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>{row[dictKey]}</TableCell>
//                                             }
                                            
//                                         })
//                                     }
//                                 </TableRow>
//                             )
//                         })
//                     }
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     )

// }

// export default DenseTable;

// import React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { v4 as uuidv4 } from 'uuid';
// import './dense_table.css';

// const defaultColumns = ['Name', 'Age', 'City', 'Country'];

// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

// function DenseTable({ columnHeaders = defaultColumns, columnRows = [] }) {
//     const maxRowsPerTable = 32;
  
//     const renderTable = (rows, isSecondTable) => (
//       <TableContainer className={`tableContainer ${isSecondTable ? 'second-table' : ''}`} component={Paper} key={uuidv4()}>
//         <Table size="small" stickyHeader aria-label="a dense table" style={{ fontFamily: 'Arial, sans-serif' }}>
//           <TableBody>
//             <TableRow style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
//               {columnHeaders.map((colName) => (
//                 <TableCell align='justify' key={uuidv4()} className='table-header-tg'>
//                   <h6 className='table-header-label' style={{ fontFamily: 'Arial, sans-serif' }}>{capitalizeFirstLetter(colName)}</h6>
//                 </TableCell>
//               ))}
//             </TableRow>
//             {rows.map((row) => (
//               <TableRow style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }} key={uuidv4()}>
//                 {columnHeaders.map((dictKey) => (
//                   <TableCell key={uuidv4()} style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px' }}>
//                     {row[dictKey]}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     );
  
//     const tableChunks = [];
//     for (let i = 0; i < columnRows.length; i += maxRowsPerTable) {
//       const chunk = columnRows.slice(i, i + maxRowsPerTable);
//       tableChunks.push(chunk);
//     }
  
//     return (
//       <>
//         {tableChunks.map((chunk, index) => renderTable(chunk, index > 0))}
//       </>
//     );
//   }
  
//   export default DenseTable;