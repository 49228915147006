// import CheckboxInput from "../raw_inputs/CheckboxInput";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDeleteIcon from "./custom_icons/CustomDeleteIcon";

function CheckboxWithLabel({ label, handleCheck = () => { }, deleteCheckbox = () => { }, enableDelete = false, checked_status=false }) {

  const handleChange = (event) => {
    if (event.target.checked == 1) {
      handleCheck(label, true);
    } else {
      handleCheck(label, false);
    }
  };

  return (
    <div>
      <span>
        <FormControlLabel
          control={<Checkbox checked={checked_status} onChange={handleChange} />}
          label={label}
        />
      </span>
      <span style={{ float: 'right' }}>
        <CustomDeleteIcon action={() => { deleteCheckbox(label) }} enableIcon={enableDelete} />
      </span>
    </div>
  )
}

export default CheckboxWithLabel;