// import React, { useContext } from 'react';
// import { AuthContext } from './AuthContext';
import { useEffect } from 'react';
// import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function LogoutElem(){
    const navigate = useNavigate();

    const handleLogout = async () => {
        await localStorage.removeItem('token');
        navigate('/login');
    }

    useEffect(() => {
        handleLogout()
    }, []);

    return (
        // <Button className='Logout' onClick={() => handleLogout()}>
        //     LOGOUT
        // </Button>
        <></>
    );

}

export default LogoutElem;